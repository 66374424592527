import sans from '../fonts/IBMPlexSans/IBMPlexSansVar-Inline'
import sans2 from '../fonts/Karla/Karla-Variable-Inline'
import serif from '../fonts/Newsreader/Newsreader-Variable-Inline'


export const fonts = {
	sans: {
		// family: "'IBM Plex Sans', serif", // debug font loading
		family: "'IBM Plex Sans', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
		face: sans
	},
	sans2: {
		// family: "'Karla', serif", // debug font loading
		family: "'Karla', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
		face: sans2
	},
	serif: {
		// family: "'Newsreader', sans-serif", // debug font loading
		family: "'Newsreader', 'Lucida Bright', 'Lucidabright', 'Lucida Serif', 'Lucida', 'DejaVu Serif', 'Bitstream Vera Serif', 'Liberation Serif', 'Georgia', serif",
		face: serif
	},
	mono: {
		family: "'monaco', 'Consolas', 'Lucida Console', monospace",
		face: ''// https://www.cssfontstack.com/Monaco
	},
}