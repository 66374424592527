import ReactDOM from 'react-dom'
import {isObject, set, get, some, throttle} from 'lodash'
import keycode from 'keycode'
import cs from './JDS/cookie/cookie'
import events from './common/events/events'
import {routerInit, updateRoute, onRouteChange, history} from './router'
import component from './common/component/component'
import diff from './common/diff/diff'
import tsk from './common/tsk/tsk'
import ops from './common/ops/ops'
import tasks from './tasks/tasks'
import operations from './operations/operations'
import state, {onChange} from './state/state'

// import {connect, connection} from './api/ws.js'
import * as serviceWorker from './serviceWorker'


window.events = events


// set up the mutable app state, hydrate from local storage or global
let appState = state.init({
		versionInfo: {version: '1.0'}
	}),
	currentState = appState,
	updatePaths = [],
	priorState = {},
	lastDiff = {},
	eventNameSpace = 'stateChange',
	subscriber = ops.eventSubscriber(eventNameSpace),
	taskManager = tsk(tasks),
	globalStateKeys = 'route touched'.split(' ')

window.currentState = appState
window.priorState = priorState
window.lastDiff = lastDiff


taskManager.start(1000, () => appState)


// keep globalStateKeys in sync with mainProps

function swapHandler(path) {
	updatePaths.push(path)
	// console.log("swapHandler", updatePaths);
	// console.time("change observation "+(++ct1));
	let changes = 0,
		lastDiff = {}

	updatePaths.forEach(path => {
		let newVal = get(currentState, path),
			oldVal = get(priorState, path),
			nIsObj = isObject(newVal),
			oIsObj = isObject(oldVal),
			hasObjDiff = nIsObj && oIsObj,
			objDiff = hasObjDiff ? diff(oldVal, newVal) : null,
			hasDiff = hasObjDiff ? !!objDiff : (newVal === oldVal),
			pdif = hasDiff ? objDiff : newVal

		// console.log("||||||||||||||", 'path', p, "pdif", pdif, "oldVal", oldVal, "newVal", newVal, diff(oldVal, newVal), oldVal === newVal, isEqual(oldVal, newVal));

		if (!pdif && newVal === oldVal) {
			// console.log("no change, skipping update", path, pdif, oldVal, newVal);
		} else {
			// console.log("update", path+"", {pdif, oldVal, newVal});
			changes++
			set(priorState, path, newVal ? JSON.parse(JSON.stringify(newVal)) : newVal)
			set(lastDiff, path, pdif)
		}
	})


	// run operations first
	updatePaths = []
	if (changes) {
		// console.log("change observation ", {priorState, currentState, lastDiff});

		// console.timeEnd("change observation "+ct1);
		// run a top down render if the route has changed
		// console.time('ops.run '+(++ct2));
		ops.run(operations, currentState, priorState, lastDiff, eventNameSpace)

		// console.timeEnd('ops.run '+ct2);
		// see render function below, all props to main must be checked here
		// console.log(globalStateKeys, upp, Object.keys(lastDiff), some(globalStateKeys, key => key in lastDiff));
		if (some(globalStateKeys, key => key in lastDiff)) {
			// console.time('topDownRender');
			render()
			// console.timeEnd('topDownRender');
		}

		// force render of individual components if they are subscribed to a state path found in diff
		// console.time('callMatching '+(++ct4));
		ops.callMatching.operation(currentState, priorState, lastDiff, 'callMatching', eventNameSpace)
		// console.timeEnd('callMatching '+ct4);
	}
}



// config components to access the serializedState
// don't load any React components before this point!!
component.configure(appState, subscriber, true)

routerInit()

// import Main from './view/Main/Main'
let App = require('./App').default


onRouteChange(routeState => {
	// websocket connection
	let tk = routeState.query.token
	if (tk) {
		console.log('got new token')
		cs.set('token', tk)
		delete routeState.query.token
		history.replace(pathname)
	}

	let token = cs.get('token')
	if (token && !connection.status) {
		console.log('connecting with token')
		connect()
	}

	console.log('initial render props', pathname + ' -', routeState, token)

	state.update('route', routeState)
	//state.update('user', props.user)
	//render(props)
})



// register the swap handler for any changes from the state mutation api
onChange((n, path) => swapHandler(path))



let doInit = true

function render() {
	let {route, user} = currentState
	console.log('render app')
	ReactDOM.render(<App {...{route, user}}/>, document.getElementById('root'), () => doInit && initFn())
}


// ----------------------------- entrypoint ---------------------------
// this first setRouteState kicks it all off
let loc = window.location
updateRoute(loc.pathname, loc.search, loc.hash)
// ----------------------------- entrypoint ---------------------------

//
if (module.hot) {
	console.log('module.hot')
	module.hot.accept('./App.jsx', () => {
		App = require('./App').default
		render()
	})
}


function initFn() {
	doInit = false

	// track window size
	let win = window,
		doc = document,
		setSizes = () => {
			let h = Math.max(doc.documentElement.clientHeight, window.innerHeight || 0),
				w = Math.max(doc.documentElement.clientWidth, window.innerWidth || 0)

			state.update(['viewportHeight'], h)
			state.update(['viewportWidth'], w)
		}

	win.addEventListener('resize', throttle(setSizes, 300, {leading: false, trailing: true}))
	setSizes() // set initial values

	// track key states
	let getKeySetter = function(val) {
		return e => {
			let code = (keycode(e) + '').replace(/left\s|right\s/, '')
			state.updateCb('keys', keys => {
				keys[code] = val
				return keys
			})
		}
	}

	doc.addEventListener('keydown', getKeySetter(true))
	doc.addEventListener('keyup', getKeySetter(false))

	// key events can get lost for various reasons causing confusing behavior
	// lets catch the modifiers that we miss by looking at mouse move events
	doc.addEventListener('mousemove', e => {
		let k = currentState.keys,
			updCtrl = k.ctrl !== e.ctrlKey,
			updShift = k.shift !== e.shiftKey,
			updCmd = k.command !== e.metaKey

		if (updCtrl || updShift || updCmd) {
			k.ctrl = e.ctrlKey
			k.shift = e.shiftKey
			k.command = e.metaKey
			state.updateCb('keys', () => k)
		}
	})
}



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

events.on('APIERROR', function(msg) {
	console.log('APIERROR', msg)
})

events.on('NOTFOUND', function(msg) {
	console.log('NOTFOUND', msg)
})
