import {assign, noop} from 'lodash'
import {createBrowserHistory} from 'history'
import queryString from 'query-string'
import {routeMatcher} from 'route-matcher'


// utils ------------------------------------------------------------
let routes = [],
	views = [],
	matchers = [],
	update = noop


export const history = createBrowserHistory()


export function initRouter(routesArray, viewsArray) {
	routes = routesArray
	views = viewsArray
	routesArray.forEach(function(v) {
		matchers.push(routeMatcher(v.route))
		appRoutes.byName[v.name] = v
		appRoutes.byAction[v.action] = v
	})
}


export function stringifyRoute(to, params, query, origin) {
	return (origin ? window.location.origin : '') + stringify(to, params, query)
}


export function stringifyRouteObj({name, params, query}) {
	return appRoutes.stringify(name, params, query)
}


export function navTo(to, params, query, replace) {
	let href = stringify(to, params, query)
	// console.log("NAV----------", to, params, query, replace, href)
	replace
		? history.replace(href)
		: history.push(href)
}


export function setRoute(route, replace) {
	let href = stringify(route.name, route.params, route.query)
	// console.log("NAV----------", to, params, query, replace, href)
	replace
		? history.replace(href)
		: history.push(href)
}


export function setQuery(route, query, replace) {
	let href = stringify(route.name, route.params, assign(route.query, query))
	// console.log("NAV----------", to, params, query, replace, href)
	replace
		? history.replace(href)
		: history.push(href)
}


export function navToUrl(url) {
	let parsed = parse(url)
	if (parsed?.match?.route?.name) {
		navTo(parsed.match.route.name, parsed.match.params, parsed.query)
	} else {
		let msg = `No valid route for url ${url}`
		console.log(msg, parsed)
		throw new Error(`${msg} ${url}`)
	}
}


export function stringify(name, params, query) {
	let uri, encodedParams = {};

	Object.keys(params||{}).forEach(key => {
		encodedParams[key] = encodeURIComponent(params[key])
	})

	routes.find((v, i) => {
		if (v.name === name) {
			let pathname = matchers[i].stringify(encodedParams),
				search = queryString.stringify(query || {})
			uri = pathname + (search ? '?'+search : '')
		}
		return uri
	})

	return uri
}


export function match(path) {
	let params,
		route = routes.find((v, i) => {
			params = matchers[i].parse(path)
			return params
		})

	return {route, params}
}


export function parse(url) {
	let parsed = queryString.parseUrl(url)
	return {...parsed, match: match(parsed.url)}
}


export function getRoute(pathname, search, hash) {
	if (pathname) {
		pathname = pathname.replace(/\/$/, '')

		let appRoute = match(pathname || '/') || {},
			route = appRoute.route || {}

		return {
			name:       route.name || '',
			action:     route.action || '',
			actionPath: route.actionPath || '',
			params:     appRoute.params || {},
			pathname:   pathname || '',
			query:      queryString.parse(search) || {},
			search:     search || '',
			hash:       (hash||'').replace(/^#/, '')
		}
	} else {
		throw new Error('pathname required')
	}
}


export function updateRoute(pathname, search, hash) {
	update(getRoute(pathname, search, hash))
}


export function onRouteChange(updateHandler) {
	update = updateHandler
	history.listen(({pathname, search, hash}) => {
		updateRoute(pathname, search, hash)
	})

	if ("onhashchange" in window) {
		window.onhashchange = () => {
			let {pathname, search, hash} = window.location
			updateRoute(pathname, search, hash)
		}
	}
}


// Link ------------------------------------------------------------
export function getHref(to, params, query, href) {
	let route = appRoutes.byName[to]

	if (!href) {
		if (to && route) {
			return appRoutes.stringify(to, params, query)
		} else if (!route) {
			throw new Error("ERROR: Missing link data - href: " + href + " to:"+ to + " params: " + params)
		}
	} else {
		return href
	}
}


export const Link = (p) => {
	let {children, href, to, params, query, stopProp, onClick, ...linkProps} = p,
		hrefVal = getHref(to, params, query, href)

	function onClickFn(e) {
		let target = p.target && p.target !== '_self',
			aNewTab = e.metaKey || e.ctrlKey || target,
			anExternalLink = hrefVal?.match(/^http/i)

		if (hrefVal && !aNewTab && !anExternalLink) {
			e.preventDefault()
			stopProp && e.stopPropagation()
			history.push(hrefVal)
		}
		onClick?.(e)
	}

	// Pluck off all non-standard props then pass the rest to the anchor tag
	return <a {...linkProps} href={hrefVal} onClick={onClickFn}>{children}</a>
}



// view routers ------------------------------------------------------------
export function router(action) {
	let view = views[action] || views.missing
	console.log({views, view, action})
	if (typeof view === 'string') {
		window.location = view
	} else {
		return view[0]
	}
}

export function subrouter(action) {
	let view = views[action] || []
	return view[1] || views.missing[0]
}



// default ------------------------------------------------------------
let appRoutes = {
	routes: [],
	byName: {},
	byAction: {},
	stringify,
	match,
	parse
}


export default appRoutes