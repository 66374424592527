// import {color, typography} from './styles'
// import {css} from '@emotion/react'
import {css} from '../styleUtils/styleUtils'


export const normalize = css`
	html {
		font-family: sans-serif;
		-ms-text-size-adjust: 100%;
		-webkit-text-size-adjust: 100%;
	}

	body {
		margin: 0;
	}

	article,
	aside,
	details,
	figcaption,
	figure,
	footer,
	header,
	hgroup,
	main,
	nav,
	section,
	summary {
		display: block;
	}

	audio,
	canvas,
	progress,
	video {
		display: inline-block;
		vertical-align: baseline;
	}

	audio:not([controls]) {
		display: none;
		height: 0;
	}

	[hidden],
	template {
		display: none;
	}

	a {
		background: transparent;
	}

	a:active,
	a:hover {
		outline: 0;
	}

	abbr[title] {
		border-bottom: 1px dotted;
	}

	b,
	strong {
		font-weight: bold;
	}

	dfn {
		font-style: italic;
	}


	mark {
		background: #ff0;
		color: #000;
	}

	small {
		font-size: 80%;
	}

	sub,
	sup {
		font-size: 75%;
		line-height: 0;
		position: relative;
		vertical-align: baseline;
	}

	sup {
		top: -0.5em;
	}

	sub {
		bottom: -0.25em;
	}

	img {
		border: 0;
	}

	svg:not(:root) {
		overflow: hidden;
	}

	figure {
		margin: 1em 40px;
	}

	hr {
		-moz-box-sizing: content-box;
		-webkit-box-sizing: content-box;
		box-sizing: content-box;
		height: 0;
	}

	pre {
		overflow: auto;
	}

	code,
	kbd,
	pre,
	samp {
		font-family: monospace, monospace;
		font-size: 1em;
	}

	button,
	input,
	optgroup,
	select,
	textarea {
		color: inherit;
		font: inherit;
		margin: 0;
	}

	button {
		overflow: visible;
	}

	button,
	select {
		text-transform: none;
	}

	button,
	html input[type="button"],
	input[type="reset"],
	input[type="submit"] {
		-webkit-appearance: button;
		cursor: pointer;
	}

	button[disabled],
	html input[disabled] {
		cursor: default;
	}

	button::-moz-focus-inner,
	input::-moz-focus-inner {
		border: 0;
		padding: 0;
	}

	input {
		line-height: normal;
		border: 0;
		border: none;
	}

	input[type="checkbox"],
	input[type="radio"] {
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		padding: 0;
	}

	input[type="number"]::-webkit-inner-spin-button,
	input[type="number"]::-webkit-outer-spin-button {
		height: auto;
	}

	input[type="search"] {
		-webkit-appearance: textfield;
		-moz-box-sizing: content-box;
		-webkit-box-sizing: content-box;
		box-sizing: content-box;
	}

	input[type="search"]::-webkit-search-cancel-button,
	input[type="search"]::-webkit-search-decoration {
		-webkit-appearance: none;
	}

	*:focus {
		outline: 0;
		outline: none;
	}

	fieldset {
		border: 1px solid #c0c0c0;
		margin: 0 2px;
		padding: 0.35em 0.625em 0.75em;
	}

	legend {
		border: 0;
		padding: 0;
	}

	textarea {
		overflow: auto;
	}

	optgroup {
		font-weight: bold;
	}

	table {
		border-collapse: collapse;
		border-spacing: 0;
	}

	td,
	th {
		padding: 0;
	}

	ul,
	ol,
	li {
		list-style: none;
	}

	ul,
	ol,
	li,
	div,
	span,
	em,
	i,
	dl,
	dd,
	dt {
		margin: 0;
		padding: 0;
	}

	html,
	body,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p,
	div,
	span,
	li,
	ul,
	ol,
	dl,
	a,
	svg,
	text {
		color: inherit;
		box-sizing: border-box;
		-webkit-backface-visibility: hidden;
	}

	a,
	a:link,
	a:visited {
		text-decoration: none;
	}
`