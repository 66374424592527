import request from 'superagent'
import noCache from 'superagent-no-cache'
import config from '../appConfig.js'
import {stringify} from '../apiRoutes'


// todo handle 401s

export const get = (route, params, query) => (
	request
		.get(stringify(route, params, query))
		.use(noCache)
		.timeout(config.timeout)
)

export const post = (route, params, query, body) => (
	request
		.post(stringify(route, params, query))
		.send(body)
		.use(noCache)
		.timeout(config.timeout)
)

export const queryFn = async ({queryKey}) => {
	const {body} = await get(...queryKey)

	return body.data
}