import React, {useState} from 'react'
import {css} from '@emotion/react'
import Message from '../Message/Message'
import SimpleCard from '../SimpleCard/SimpleCard'
import Input from '../Input/Input'
import Button from '../Button/Button'
import {ReactComponent as Send} from '../SvgIcons/send.svg'
import {ReactComponent as Woosh} from '../SvgIcons/wooshAnim.svg'
import {ReactComponent as Key} from '../SvgIcons/key.svg'


let $ = s => document.querySelector(s)

const SignIn = ({onSubmit, error}) => {
	let [sending, setSending] = useState(false),
		[sent, setSent] = useState(false),
		minDuration = 800,
		complete = () => setSent(true),
		send = e => {
			e.preventDefault()
			if (!sending) {
				setSending(true)
				let start = Date.now()
				onSubmit?.($('#jds-signIn-input').value, () => {
					let remaining = Math.max(minDuration - (Date.now() - start), 0)
					setTimeout(complete, remaining)
				})
				!onSubmit && setTimeout(complete, minDuration)
			}
		}

	return (
		<div className="jds-signIn" css={styles} data-sending={sending} data-sent={sent}>
			<h1 className="logo"><img alt="Juniper Notebook" src="/assets/JuniperNotebook2.svg"/></h1>
			<Message {...{error}}/>
			<SimpleCard>
				<form className="signInForm" onSubmit={send}>
					<h3>Sign In / Sign Up</h3>
					<div style={{position: 'relative'}}>
						<Input id="jds-signIn-input" className="emailInput" type="email" required placeholder="your@email.here" />

						<Button id="buttonParent" type="submit">
							<Woosh id="woosh" />
							<div className="iconButton2"><Send id="send"/></div>
						</Button>
					</div>
				</form>

				<div className="keySent">
					<h3>Access Key Sent!</h3>
					<Key id="key" style={{width: '75px', height: '115px'}}/>
					<span>
						Check your email now for the<br/>
						secure sign in link.
					</span>
				</div>
			</SimpleCard>
		</div>
	)
}

export default SignIn



let styles = css`
	text-align: left;
	margin: 0 auto;

	h1.logo {
		width: 100%;
		text-align: center;
		padding-bottom: 28px;
	}

	.logo img{
		position: relative;
		top: 40px;
		width: 400px;
		height: 110px;
	}

	.signInForm {
		position: relative;
		z-index: 2;
	}

	.jds-simpleCard, .keySent {
		width: 550px;
		padding: 52px 65px 26px 75px;
		margin: 0 auto 60px auto;
		border: none !important;

		h3 {
			font-weight: 260;
			font-size: 2.25em;
			margin-left: -2px;
			margin-bottom: .6em;
		}
	}

	.iconButton2 {
		transform: translate(0, 0);
		transition: transform, opacity;
		transition-duration: 300ms;
		transition-timing-function: ease-in-out;
		position: absolute;
		top: 0;
		opacity: 1;
		right: -10px;

		&:hover {
			transform: translate(-8px, 0);
		}
	}

	.emailInput {
		opacity: 1;
		transition: opacity;
		transition-duration: 300ms;
		transition-timing-function: ease-in-out;
	}

	#send {
		* {fill: var(--button-color);}
	}

	#woosh {
		position: absolute;
		top: 0;
		right: 14px;
		width: 40px;
		height: 40px;

		* {stroke: var(--button-color);}
	}

	.keySent {
		z-index: 1;
		opacity: 0;
		transition: opacity;
		transition-duration: 300ms;
		transition-timing-function: ease-in;
		position: absolute;
		padding: 58px 65px 0 65px;
		top: 0;
		left: 0;

		#key {
			position: absolute;
			left: 65px;
			top: 50px;

			* {fill: var(--button-color);}
		}

		h3, span {padding-left: 130px;}
		span {
			font-size: 19px;
			font-weight: 300;
			display: block;
			color: var(--headline-color);
			line-height: 1.3em
		}
	}

	#buttonParent {
		border: none;
		overflow: visible;
		position: absolute;
		right: 0
	}

	.iconButton2 {
		position: relative;
		width: 36px;
		height: 36px;
		top: 2px;
	}

	&[data-sending="true"] {
		.emailInput {opacity: .5;}

		#woosh {
			transition-duration: 1500ms;

			* {animation-play-state: running !important;}
		}

		.iconButton2 {
			transform: translate(26px, 0);
			transition-duration: 800ms;
			transition-timing-function: ease-out;
		}
	}


	&[data-sent="true"] {
		.emailInput {opacity: 0;}

		#woosh, .signInForm h3 {
			opacity: 0;
			transition: opacity;
			transition-duration: 300ms;
		}

		.iconButton2 {
			transform: translate(100px, 0);
			opacity: 0;
			transition-duration: 300ms;
			transition-timing-function: ease-out;
		}

		.keySent {
			opacity: 1;
			transition-delay: 300ms;
		}
	}


	@media (max-width: 599px) {
		.logo img {
			width: 300px;
			height: auto;
		}

		.signInForm h3 {
			font-size: 250%;
			text-align: center;
			padding-top: 20px;
		}

		.jds-simpleCard {
			font-size: 90%;
			padding: 0 !important;
			margin: 0 auto !important;
			width: auto !important;
			position: relative;
			text-align: center;

		}

		#jds-signIn-input {
			z-index: 3;
			min-width: auto;
			width: 400px;
			margin: 20px 0 50px 0;
			text-align: center;
			overflow: visible;
		}

		#buttonParent {
			z-index: 2;
			min-width: auto;
			width: 50%;
			margin: 0 auto;
			position: relative;
			text-align: center;
			transform: translateX(-30px);
			-webkit-tap-highlight-color: transparent !important;
			overflow: visible;

			.iconButton2 {
				overflow: visible;
				margin: 0 auto;
				width: 50px;
				height: 50px;
				right: -30px;
			}

			#woosh {
				width:100%;
				height: 50px;
				margin: 1px 7px 0 0
			}
		}

		.keySent {
			margin: 0 auto;
			padding: 0;
			transform: translate(-10px, -240px);
			position: relative;
			width: auto;
			text-align: center;

			h3 {
				text-align: center;
				font-size: 250%;
				margin: 0 0 0 20px;
				padding: 0 0 30px 0;
			}

			span {
				margin: 0;
				padding: 0 0 30px 0;
			}

			#key {
				margin: 10px auto 40px auto;
				position: static;
			}
		}
	}
`