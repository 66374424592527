import cookie from '../JDS/cookie/cookie'
import {user} from '../api/user'

export default {

	defaultTask: {
		interval: 1000,
		task: function(s) {
			console.log('default task')
		}
	}
}