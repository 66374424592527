
// this file loaded in client and server so must be node compatible

import {routeMatcher} from 'route-matcher'
import queryString from 'query-string'
import appConfig from './appConfig.js'


let apiPath = appConfig.publicApiPath,
	matchers = {}

export const routes = []

export const apiRoutes = {
	authenticate:		apiPath + '/authenticate',
	claim:				apiPath + '/claim',
	refresh:			apiPath + '/refresh',
	user:				apiPath + '/user',
	docs: 				apiPath + '/docs',
	notes: 				apiPath + '/notes',
	fieldnotes: 		apiPath + '/fieldnotes',
}


// create matchers and route objects for each route
Object.keys(apiRoutes).forEach(name => {
	let pattern = apiRoutes[name]
	matchers[pattern] = matchers[name] = routeMatcher(pattern) // lookup by name or route
	routes.push({name, route: pattern})
})


export function stringify(name, params, query) {
	let encodedParams = {}

	Object.keys(params || {}).forEach(key => {
		encodedParams[key] = encodeURIComponent(params[key])
	})

	let	matcher = matchers[name],
		pathname = matcher && matcher.stringify(encodedParams),
		search = query ? queryString.stringify(query || {}) : ''

	return pathname + (search ? '?' + search : '')
}


export function match(path) {
	let params,
		route = routes.find(route => {
			params = matchers[route.name].parse(path)
			return params
		})

	return {route, params}
}
