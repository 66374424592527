
import Title from 'react-document-title'
import If from '../../JDS/If/If'
import cookie from '../../JDS/cookie/cookie'


export default function NotFound() {

	let st = cookie.get('st'),
		at = cookie.get('at')

	return (
		<Title title='Not Found'>
			<div id="notFound" className="page">
				<If is={st && at}>
					<h1>404 Not Found</h1>
				</If>
			</div>
		</Title>
	)
}

