import React from 'react'
import If from '../If/If'
import {ReactComponent as Sun} from '../SvgIcons/sun.svg'
import {ReactComponent as Moon} from '../SvgIcons/moon.svg'
import './ThemeButton.styl'


export default function ThemeButton({theme = 'light', setTheme}) {
	let isDarkTheme = !!theme.match(/dark/i)
	return (
		<div id="JDS-ThemeButton">
			<If is={isDarkTheme}><div onClick={() => setTheme('light')}>
				<Sun className="svgIcon" />
			</div></If>
			<If is={!isDarkTheme}><div onClick={() => setTheme('dark')}>
				<Moon className="svgIcon" />
			</div></If>
		</div>
	)
}
