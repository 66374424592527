import React, {useState} from 'react'
import {map} from 'lodash'
import ClickAwayListener from 'react-click-away-listener'
import {css} from '@emotion/react'


const styles = css`
	position: relative;

	.jds-menu-button {
		h4 {
			font-size: 20px;
			font-weight: 600;
		}
	}

	.jds-menu-list {
		color: var(--menu-color);
		background: var(--menu-background);
		border: solid 1px var(--menu-border-color);
		border-radius: var(--menu-border-radius);
		white-space: nowrap;
		position: absolute;
		margin: 4px 0 0 0;
		padding: 6px 0;
		box-shadow: var(--menu-shadow);

		& > * {
			min-width: 100px;
			cursor: pointer;
			margin: 0;
			padding: 2px 20px;
			border-left: solid 4px transparent;
			letter-spacing: var(--menu-letterSpacing);
			font-weight: var(--menu-weight);

			&:hover {
				letter-spacing: initial;
				font-weight: var(--menu-hover-weight);
				color: var(--menu-hover-color);
				border-left-color: var(--menu-indicator-color);
			}
		}
	}
`


export default function Menu({children, items, itemView, listParent, ...props}) {
	const [open, setOpen] = useState(false),
		close = () => setOpen(false),
		ListParent = listParent || 'ul'

	return (
		<div className="jds-menu" css={styles} {...props}>
			<ClickAwayListener onClickAway={close}>
				<div>
					<div className="jds-menu-button" onClick={() => setOpen(!open)}>
						{children}
					</div>
					{open && (
						<ListParent className="jds-menu-list" onClick={close}>
							{map(items, itemView)}
						</ListParent>
					)}
				</div>
			</ClickAwayListener>
		</div>
	)
}
