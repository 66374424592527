import {Link} from '../../router'


export default function Stream() {
	return (
		<div id="activity" className="page">
			Notebooks
		</div>
	)
}

