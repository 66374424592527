// this file loaded in client and server so must be node compatible
import appConfig from './appConfig.js'


let baseRoute = appConfig.baseRoute

let routes = [
	{route: `${baseRoute}/login`, 							name: 'Login', 				action: 'login'},
	{route: `${baseRoute}/claim`, 							name: 'Login Claim', 		action: 'claim'},
	{route: `${baseRoute}/`, 								name: 'Root', 				action: 'root'},
	{route: `${baseRoute}/notebooks`,						name: 'Notebooks', 			action: 'notebooks'},
	{route: `${baseRoute}/notebooks/:nbId/pages`,			name: 'Pages',				action: 'pages'},
	{route: `${baseRoute}/notebooks/:nbId/pages/:pgId`, 	name: 'Page', 				action: 'page'},
	{route: `${baseRoute}/stream`,							name: 'Stream', 			action: 'stream'},
	{route: `${baseRoute}/account`, 						name: 'Account', 			action: 'account'},
	{route: `${baseRoute}/storybook`, 						name: 'Storybook', 			action: 'storybook'}
]

export default routes