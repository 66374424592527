import {lightFormat} from 'date-fns'
import {css} from '@emotion/react'
import If from '../../JDS/If/If'
import Timeline from '../../JDS/Timeline/Timeline'
import {Link} from '../../router'
import {useFieldNotes} from '../../api/notes'


export default function Stream({route}) {
	let {data, error, isLoading} = useFieldNotes(route.query)

	// console.log(route)

	return (
		<div id="activity" className="page">
			Stream{isLoading ? '...' : ''}

			<If is={data}>
				<Timeline items={data} />
			</If>
		</div>
	)
}



