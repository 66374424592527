import React from 'react'
// import Link from '../../common/Link/Link'
import {useUser} from '../../api/user'


function Avatar({id}) {
	let {isLoading, isError, data, error} = useUser()

	console.log('Avatar', {isLoading, isError, data, error})

	if (isLoading) return <div>loading user...</div>
	if (isError) return <div>error</div>
	return <div>{data?.getUser?.email}</div>
}

export default function Account({id}) {

	// console.log('account', user)

	return (
		<div id="page" className="page">
			Account
			<Avatar {...{id}} />
		</div>
	)
}

