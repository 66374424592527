import React from 'react'
import {css} from '@emotion/react'


let styles = css`
	background: transparent;
	position: relative;
	color: var(--button-color);
	border: solid 1px var(--button-outline);
	border-radius: var(--button-radius);
	overflow: hidden;
	cursor: pointer;
	transform: scale(1);
	transition: all;
	transition-duration: 300ms;
	margin: 0 8px 20px 0;
	padding: 0 .7rem;

	& > svg * {
		stroke: var(--button-color);
	}

	&:hover {
		border-color: var(--button-outlineActive);
		transition-duration: 100ms;
	}

	&:focus {
		outline: none;
	}

	&:active {
		transform: scale(.95);
		transition-duration: 100ms;
	}

	&[disabled] {
		cursor: not-allowed;
	}

	&.iconButton {
		border: solid 1px transparent;
		padding: 0;


		& > svg {
			width: 26px;
			height: 26px;
		}

		& > svg * {
			stroke: var(--button-outline);
			fill: var(--button-outline);
		}

		&:hover {
			//border: solid 1px var(--button-outline);
			& > svg * {
				stroke: var(--button-outlineActive);
				fill: var(--button-outlineActive);
			}
		}
	}

	&.iconButton2 {
		border: none;
		padding: 0;

		& > svg {
			width: 36px;
			height: 36px;
		}

		& > svg * {
			stroke: var(--button-outlineActive);
			fill: var(--button-outlineActive);
		}
	}

	&.iconButton3 {
		border: none;
		padding: 0;

		& > svg {
			width: 36px;
			height: 36px;
		}

		& > svg * {
			stroke: var(--button-outlineActive);
			stroke-width: 0.5px;
			fill: var(--button-outlineActive);
		}
	}
`



const Button = ({className = '', children, ...rest}) => {
	return (
		<button {...{className: className + ' jds-button', css: styles}} {...rest}>{children}</button>
	)
}

export default Button