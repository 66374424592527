import React from 'react'
import {css} from '@emotion/react'
import cs from '../../JDS/cookie/cookie'
import {signIn} from '../../api/user'
import {navTo} from '../../router'
import {ReactComponent as Notebook} from '../../JDS/SvgIcons/notebook2.svg'


let styles = css`
	text-align: center;
	margin: 0 60px 60px 60px !important;

	@keyframes fadeIn {
		0%   {opacity: 0;}
		100% {opacity: 1;}
	}
	@keyframes zoomIn {
		0%   {transform: scale(0.65);}
		100% {transform: scale(1);}
	}

	&.box {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: center;
		align-content: center;
		align-items: center;
	}

	&.box svg {
		order: 1;
		flex-grow: 0;
		flex-shrink: 1;
		flex-basis: auto;
		align-self: auto;
		min-width: 0;
		min-height: auto;
	}


	#claim {
		animation: fadeIn 5000ms cubic-bezier(0, 0.97, 0.27, 0.93) 0s 1 normal none running;
	}

	svg {
		opacity: 1;
		transform: scale(1);
		animation: zoomIn 5000ms cubic-bezier(0, 0.97, 0.27, 0.93) 0s 1 normal none running;
		width: 60vh;
		max-width: 320px;

		* {
			fill: var(--color-primary);
		}
	}
`

export default function Claim({route}) {
	return (
		<div id="Claim" className="page box" css={styles}>
			<div id="claim">
				<Notebook/>
			</div>
		</div>
	)

}



