import {merge, cloneDeep} from 'lodash'
import {cssVars, setSat, setLum, setSatLum} from '../../../styleUtils/styleUtils'
import {color} from '../../../shared/styles'
import {fonts} from '../../fonts'
import ltTheme from '../light/light'


let {
	type: ltType,
	colors: ltColors,
	metrics: ltMetrics,
	elements: ltElements
} = cloneDeep(ltTheme.tokens)

export const type = merge({}, cloneDeep(ltType), {
	bodyWeight: 200	//themeStyle === 'dark' ? 200 : 300,
})

export const colors = merge({}, (ltColors), {
	// black glass theme
	background: color.darkest,
	midground: color.darker2,
	foreground: color.light,

	primary: color.hotPurple,
	primaryFaded: setSatLum(color.hotPurple, 75, 20),
	primaryGrey: setSat(color.hotPurple, 0)

	// slate theme
	// background: color.darkestSlate,
	// midground: color.darker2,
	// foreground: color.lightestSlate,
	//
	// primary: color.hotPurple,
	// primaryFaded: setSatLum(color.hotPurple, 70, 20),
	// primaryGrey: setSat(color.hotPurple, 0)

	// green theme
	// primary: color.seafoam,
	// primaryFaded: setSatLum(color.seafoam, 75, 20),
	// primaryGrey: setSat(color.seafoam, 0)


})

export const metrics = merge({}, cloneDeep(ltMetrics), {
	// customize metrics here
})

export const elements = merge({}, cloneDeep(ltElements), {
	body: {
		background: colors.background,
		border: colors.midground
	},
	text: {
		color: colors.foreground
	},
	// headline: {
	// 	color: color.hotPurple,
	// },

	container: {
		color: colors.foreground,
		border: {
			color: colors.primaryFaded,
		}
	},

	button: {
		background: colors.background,
		outline: colors.primaryFaded,
	},

	menu: {
		color: colors.foreground,
		background: colors.background,
		shadow: `0 2px 25px 5px ${setSatLum(colors.primaryFaded, 90, 14)}`,
		weight: 150,
		letterSpacing: '0.026rem',

		indicator: {
			background: colors.primaryFaded
		},

		hover: {
			color: colors.foreground,
		},

		border: {
			color: colors.primaryFaded,
		}
	},

	input: {
		color: setSatLum(colors.primary, 100, 60),
		placeholderOpacity: 0.8,
		placeholderColor: setSatLum(colors.primary, 100, 65),
		disabledPlaceholderColor: setLum(colors.primaryGrey, 55),
	}
})


const theme = {
	key: 'dark',
	title: 'App Dark',
	style: 'dark',
	fonts,
	tokens: {
		type,
		colors,
		metrics,
		...elements
	},
	styles: [
		...ltTheme.styles
	]
}

export default theme