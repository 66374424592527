import React from 'react'
import {css} from '@emotion/react'
import {format} from 'date-fns'
import List from '../List/List'


export const timelineDate = (ts) => {
	let date = new Date(parseInt(ts, 10)),
		thisYr = new Date().getFullYear(),
		yr = date.getFullYear(),
		moDay = format(date, 'LLL d')

	return thisYr === yr
		? <div className="moDay">{moDay}</div>
		: <div className="moDayYr">{moDay}<br/>{yr}</div>
}


const Timeline = ({items, itemView, id, ...props}) => {
	return (
		<List {...{
			items,
			itemView: TimelineItem,
			id,
			...props,
			css: styles
		}}/>
	)
}

const TimelineItem = ({text, subject, created}) => (
	<>
		<p>{text.slice(0, 100)}</p>
		<div className="jdsTimeline-date">
			{timelineDate(created)}
		</div>
	</>
)


let styles = css`
	--tl-borderThickness: 1px;
	--tl-padding: 40px;
	--tl-circleSize: 20px;
	--tl-circleRadius: calc(var(--tl-circleSize) * 0.5);
	--tl-circleLeft: calc(calc(var(--tl-padding) + var(--tl-circleRadius) + var(--tl-borderThickness)) * -1);


	margin-left: 60px;

	ul {
		padding: var(--tl-padding);
		border-left: solid 1px var(--color-primaryGrey);

		li {
			position: relative;
			font-size: 20px;
			line-height: 20px;
			margin: 0;
			cursor: pointer;
			min-width: 200px;

			p {
				margin: 0;
				padding: 3px 0 20px 0;
			}

			.jdsTimeline-date {
				text-align: right;
				line-height: var(--tl-circleSize);
				width: var(--tl-circleSize);
				height: var(--tl-circleSize);
				border-radius: var(--tl-circleSize);
				background: var(--color-background);
				position: absolute;
				top: 0;
				left: var(--tl-circleLeft);
				border: solid var(--tl-borderThickness) var(--color-primaryGrey);

				&>div {
					width: 80px;
					font-size: 14px;
					line-height: var(--tl-circleSize);
					position: relative;
					top: 0;
					right: 100px;
					white-space: nowrap;
					color: var(--color-primaryGrey);

					&.moDayYr {
						line-height: 16px;
					}
				}
			}

			&:hover p {
				color: var(--color-primary);

			}
		}
	}
`


export default Timeline
