import {initRouter, navTo, stringifyRouteObj} from './JDS/router/router'
import cs from './JDS/cookie/cookie'
import {refresh} from './api/user'
import routes from './routes'


// prevent pmnistate init problems
export function routerInit() {
	let views = require('./views/views').default
	initRouter(routes, views)
}


export * from './JDS/router/router'


export function authenticate(route) {
	let appPage = isAppPage(route),
		{action} = route,
		isClaim = action === 'claim',
		isLogin = action === 'login'

	if (appPage) {
		let token = cs.get('st'),
			access = cs.get('at')

		console.log('authenticate', {action, appPage, isClaim, isLogin, token})

		if (!isClaim && !isLogin) {
			if (token) {
				!access && refresh(() => {
					let token = cs.get('st'),
						access = cs.get('at')

					if (token && access) {
						console.log('reload...')
						navTo(stringifyRouteObj(route) || route.pathname, null, null, true)
					} else {
						console.log('no reload??')
					}
				})
			} else {
				navTo('Login', null, {redir: stringifyRouteObj(route) || route.pathname}, true)
			}
		}
	}
}

// todo use a whitelist, needs to keep up with adding static pages to site
// consider above authentication flow
export function isAppPage({action}) {
	return action !== 'root'
}



