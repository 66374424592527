import {map, reduce, transform, forEach, isFunction, memoize} from 'lodash'
import {cssVars} from '../../styleUtils/styleUtils'


export default function initTheme({
	title = 'Theme', 	// user friendly title
	key = 'theme', 		// unique id for the theme
	style = 'light',
	fonts,
	tokens: _tokens,
	styles
}) {
	let tokens = {
			font: fontTokens(fonts),
			..._tokens
		},
		vars = cssVars(tokens),
		theme = {
			fonts,
			tokens,
			vars,
			styles: [
				theme => fontCss(theme.fonts),
				theme => `
					:root body {
						${theme.vars}
					}
				`,
				...styles
			]
		}

	theme.css = () => themeCss(theme)

	return theme
}

export const themeCss = memoize(function(theme) {
	return map(theme.styles, style => (
		isFunction(style)
			? style(theme)
			: style
	)).join('\n\n')
})

export function fontCss(fonts) {
	return reduce(fonts, (css, font) => css += `${font.face}\n\n`, '')
}

export function fontTokens(fonts) {
	return transform(fonts, (tokens, font, key) => {
		tokens[key + 'FontFamily'] = font.family
	}, {})
}