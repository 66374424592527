import request from 'superagent'
import noCache from 'superagent-no-cache'
import config from '../appConfig.js'
import {stringify, routes, apiRoutes} from '../apiRoutes'
import {useQuery} from 'react-query'


export function listNotes(token, email, handler) {
	let url = stringify(routes.notes, null, {email})
	request.get(url).use(noCache).timeout(config.timeout).end(handler)
}

export const useFieldNotes = queryParams => {
	let {data, ...rest} = useQuery([apiRoutes.fieldnotes, null, queryParams])

	return {
		data: data?.getUser?.fieldNotes || null,
		...rest
	}
}

