
import cookie from '../JDS/cookie/cookie'
import {navTo, authenticate, stringifyRouteObj, isAppPage, navToUrl} from '../router'
import {claim, user} from '../api/user'
import state from '../state/state'


export default {

	// any: {
	// 	operation: function(n, o, c) {
	// 		console.log('changes', c)
	// 	}
	// },


	authPromptLogin: {
		// redirects to login if needed
		change: ['route'],
		operation: ({route}) => authenticate(route)
	},

	authClaim: {
		// execute claim, if is valid redirects to redir with new tokens loaded via cookie,
		// on error redirects to login
		pending: false,
		change: ['route'],
		operation: function(n, o, c) {
			let {route} = n,
				{action, query} = route,
				{redir, code, email} = query

			if (action === 'claim' && !this.pending) {
				this.pending = true
				claim(email, code, redir, (err) => {
					setTimeout(()=> this.pending = false, 100)
					let st = cookie.get('st'),
						at = cookie.get('at')

					console.log('claim finished', {email, code, redir, st, at, err})

					if (!err && st && at) {
						if (redir?.match?.('/f/')) {
							window.location = window.location.origin + redir
						} else {
							redir
								? navToUrl(redir)
								: navTo('Stream')
							state.set('authError', '')
						}
					} else {
						console.log('There was an error claiming token, please try again.')
						state.set('authError', 'There was an error claiming token, please try again.')
						navTo('Login', null, {redir}, true)
					}
				})
			}
		}
	},

	// authRefreshAccessToken: {
	// 	pending: false,
	// 	change: ['route'],
	// 	operation: function() {
	//
	// 		if (isAppPage(route) && !this.pending) {
	// 			let st = cookie.get('st'),	// long-lived session token
	// 				at = cookie.get('at')	// short-lived access token
	//
	// 			console.log('authRefreshAccessToken', {st, at})
	//
	// 			if (st && !at) {
	// 				this.pending = true
	// 				console.log('haveSession? missing "at", load user, (gets new at)')
	// 				user(user => {
	// 					this.pending = false
	// 					console.log('-------- user loaded should we redirect here?', {user})
	// 				})
	// 			}
	// 		}
	// 	}
	// }
}