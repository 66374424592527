import request from 'superagent'
import noCache from 'superagent-no-cache'
import {useQuery} from 'react-query'
import config from '../appConfig.js'
import {stringify, apiRoutes} from '../apiRoutes'
import {get} from './req'


export function signIn(email, redir, handler) {
	let url = stringify(apiRoutes.authenticate, null, {email, redir})
	request.get(url).use(noCache).timeout(config.timeout).end(handler)
}

export function claim(email, code, redir, handler) {
	let url = stringify(apiRoutes.claim, null, {email, code, redir})
	request.get(url).use(noCache).timeout(config.timeout).end(handler)
}

export function refresh(handler) {
	let url = stringify(apiRoutes.refresh)
	request.get(url).use(noCache).timeout(config.timeout).end(handler)
}

export function fetchUser(handler) {
	console.log('--- fetch user url ----', apiRoutes.user)
	let url = stringify(apiRoutes.user)
	request.get(url).use(noCache).timeout(config.timeout).end(handler)
}



export const useUser = () => (
	useQuery(apiRoutes.user, {
		placeholderData: {
			accountId: '',
			email: '',
			name: ''
		}
	})
)

