import React from 'react'
import {Global, css, withTheme, ThemeProvider} from '@emotion/react'
import themes from '../themes/app'


const makeGlobalStyles = theme => {
	return css`${theme.css(theme)}`
}

const GlobalStyles = withTheme(({theme}) => <Global styles={makeGlobalStyles(theme)} />)

export const GlobalStyleFragment = ({theme = 'light', children}) => {
	let themeObj = themes.get(theme) || {css: () => ''}
	console.log({themes, theme})
	return (
		<ThemeProvider theme={themeObj}>
			<GlobalStyles/>
			{children}
		</ThemeProvider>
	)
}