import light from './light/light'
import dark from './dark/dark'
import {registry} from '../../registry/registry'
import initTheme from '../base/base'


const themes = registry(initTheme)

themes.add(light)
themes.add(dark)

export default themes


