
// Global style variables
// import {setSatLum} from '../styleUtils/styleUtils'

export const background = {
	app: '#F6F9FC',
	appInverse: '#7A8997',
	positive: '#E1FFD4',
	negative: '#FEDED2',
	warning: '#FFF5CF',
}

export const color = {
	// Palette
	primary: '#ff4785', // coral
	secondary: '#1EA7FD', // ocean
	tertiary: '#DDDDDD',

	red: '#ff006a',
	orange: '#FC521F',
	gold: '#FFAE00',
	green: '#66BF3C',
	aquamarine: '#7FFFD4',
	seafoam: '#37D5D3',
	blue: '#0071bc',

	darkPurple: '#4c2c92',
	purple: '#6F2CAC',
	mediumPurple: '#9846db',
	hotPurple: '#cd13f0',
	lightPurple: '#e0c8f4',
	lightPink: '#f4cbfc',

	ultraviolet: '#2A0481',
	ultravioletDark: '#14092d',

	lightestSlate: '#cdcdcd',
	darkestSlate: '#18181b',

	// Monochrome
	lightest: '#FFFFFF',
	lighter: '#F8F8F8',
	light: '#F3F3F3',
	mediumLight: '#EEEEEE',
	medium: '#DDDDDD',
	mediumDark: '#999999',
	dark: '#666666',
	darker: '#333333',
	darker2: '#222222',
	darkest: '#000000',

	border: 'rgba(0,0,0,.1)',

	// Status
	positive: '#66BF3C',
	negative: '#FF4400',
	warning: '#E69D00',
}

export const spacing = {
	padding: {
		small: 10,
		medium: 20,
		large: 30,
	},
	borderRadius: {
		small: 5,
		default: 10,
	},
}

export const breakpoint = 600
export const pageMargin = '5.55555'

export const pageMargins = `
	padding: 0 ${spacing.padding.medium}px;
	@media (min-width: ${breakpoint * 1}px) {
		margin: 0 ${pageMargin * 1}%;
	}
	@media (min-width: ${breakpoint * 2}px) {
		margin: 0 ${pageMargin * 2}%;
	}
	@media (min-width: ${breakpoint * 3}px) {
		margin: 0 ${pageMargin * 3}%;
	}
	@media (min-width: ${breakpoint * 4}px) {
		margin: 0 ${pageMargin * 4}%;
	}
`
