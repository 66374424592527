
export function registry(initFn, getFn) {
	let items = {},
		reg = {
			add(item) {
				items[item.key] = initFn
					? initFn(item)
					: item
			},
			get(key) {
				return getFn
					? getFn(items[key])
					: items[key]
			}
		}

	return reg
}