import {isString, isArray, map, forEach, isPlainObject} from 'lodash'
import {hsluvToRgb, rgbToHsluv, hexToHsluv, hsluvToHex} from 'hsluv'

// support css syntax without processing
export const css = (strings, ...values) => (
	String.raw({ raw: strings }, ...values)
)


// https://css-tricks.com/converting-color-spaces-in-javascript/

let hexRe = /^#([A-Fa-f0-9]{3}){1,2}$/,
	rgbRe = /^rgb\s*\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*\)$/i

export function parseRgbStr(input) {
	let m = input.match(rgbRe)
	if (m) {
		return [m[1], m[2], m[3]]
	}
}


export function hexToRgb(hex){
	if (hexRe.test(hex)) {
		let c = hex.substring(1).split('')
		if (c.length === 3) {
			c = [c[0], c[0], c[1], c[1], c[2], c[2]]
		}
		c = '0x' + c.join('')
		return [(c >> 16) & 255, (c >> 8) & 255, c & 255]
	}

	throw new Error('Invalid hex color format', hex)
}

export function hexToRgbaStr(hex, alpha = 1) {
	return rgbToRgbaStr(hexToRgb(hex), alpha)
}

export function rgbToRgbaStr(rgb, alpha = 1) {
	return `rgb(${rgb.join(',')},${alpha})`
}

export function colorToRgba(color, alpha) {
	let {hex} = colorInfo(color)
	return hexToRgbaStr(hex, alpha)
}

function rgbHexComp(c = 0) {
	var hex = parseInt(c, 10).toString(16)
	return hex.length === 1 ? '0' + hex : hex
}

export function rgbToHex([r = 0, g = 0, b = 0]) {
	return '#' + rgbHexComp(r) + rgbHexComp(g) + rgbHexComp(b)
}

const hslHexComp = (h, s, l, n) => {
	l /= 100
	let a = s * Math.min(l, 1 - l) / 100,
		k = (n + h / 30) % 12,
		color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1)

	return Math.round(255 * color).toString(16).padStart(2, '0')
}

export const hslToHex = ([h = 0, s = 0, l = 0]) => (
	`#${hslHexComp(h, s, l, 0)}${hslHexComp(h, s, l, 8)}${hslHexComp(h, s, l, 4)}`
)

function rgbToHsl([r, g, b]) {
	// Make r, g, and b fractions of 1
	r /= 255
	g /= 255
	b /= 255

	// Find greatest and smallest channel values
	let cmin = Math.min(r, g, b),
		cmax = Math.max(r, g, b),
		delta = cmax - cmin,
		h = 0,
		s = 0,
		l = 0

	// Calculate hue
	// No difference
	if (delta === 0) {
		h = 0
	// Red is max
	} else if (cmax === r) {
		h = ((g - b) / delta) % 6
	// Green is max
	} else if (cmax === g) {
		h = (b - r) / delta + 2
	// Blue is max
	} else {
		h = (r - g) / delta + 4
	}

	h = Math.round(h * 60)

	// Make negative hues positive behind 360°
	if (h < 0) {
		h += 360
	}

	// Calculate lightness
	l = (cmax + cmin) / 2

	// Calculate saturation
	s = delta === 0
		? 0
		: delta / (1 - Math.abs(2 * l - 1))

	// Multiply l and s by 100
	s = +(s * 100).toFixed(1)
	l = +(l * 100).toFixed(1)

	return [h, s, l]
}

export function hexToHsl(hex) {
	return rgbToHsl(hexToRgb(hex))
}

export function colorToHex(color) {
	let isHex = !!hexRe.test(color),
		isHsl = isArray(color),
		rgb = !isHex && !isHsl && parseRgbStr(color)

	if (isHex) {
		return color
	} else if (isHsl) {
		return hslToHex(color)
	} else if (rgb) {
		return rgbToHex(rgb)
	} else {
		throw new Error('unknown color format')
	}
}

export function colorToHsluv(color) {
	let hex = colorToHex(color)
	return hexToHsluv(hex)
}

function roundedArray(a) {
	return map(a, v => Math.round(v * 10) / 10)
}

export function colorInfo(color) {
	let hex = colorToHex(color).toLowerCase()

	return {
		input: color,
		hex,
		rgb: hexToRgb(hex),
		hsl: roundedArray(hexToHsl(hex)),
	}
}

export function colorVals(color) {
	let hex = colorToHex(color).toLowerCase(),
		hsluv = hexToHsluv(hex)

	return {
		input: color,
		raw: {
			hex,
			rgb: hexToRgb(hex),
			hsl: roundedArray(hexToHsl(hex)),
		},
		HSLuv: {
			hex: hsluvToHex(hsluv),
			rgb: hsluvToRgb(hsluv),
			hsl: roundedArray(hsluv)
		}
	}
}

export const hsluvOps = {
	// [H°, S%, L%]
	noop:   ([h, s, l]) => [h, s, l],
	setHue: ([h, s, l], hue) => [hue, s, l],
	setSat: ([h, s, l], sat) => [h, sat, l],
	setLum: ([h, s, l], lum) => [h, s, lum],
	setSatLum: ([h, s, l], {sat, lum}) => [h, sat ?? s, lum ?? l],
}

export function hsluvOp(color, opName, options) {
	let hsluv = colorToHsluv(color),
		op = hsluvOps[opName],
		result = op
			? op(hsluv, options)
			: hsluv

	return hsluvToHex(result)
}

export const pastel = color => hsluvOp(color, 'setLum', 80)
export const setLum = (color, lum) => hsluvOp(color, 'setLum', lum)
export const setSat = (color, sat) => hsluvOp(color, 'setSat', sat)
export const setSatLum = (color, sat, lum) => hsluvOp(color, 'setSatLum', {sat, lum})
export const hsluv = color => hsluvOp(color, 'noop')


// turns arbitrarily deeply nested objects into css vars of --key-subkey-subsusbkey: value;
function processCssVarItem(item, keys = [], accum = []) {
	forEach(item, (val, key) => {
		!keys.length && (accum.push('')) // add space to divide top level sections
		let levelKeys = (keys).concat([key])
		isPlainObject(val)
			? accum.concat(processCssVarItem(val, levelKeys, accum))
			: accum.push(`--${levelKeys.join('-')}: ${val};`)
	})
	return accum
}

export function cssVars(themeItems) {
	let vars = processCssVarItem(themeItems)
	return vars.join('\n')
}
