import React from 'react'
import {css} from '@emotion/react'


const styles = css`
	background: transparent;
	color: var(--input-color);
	border: none;
	border-bottom: solid 2px var(--input-border-color);
	border-radius: 0;
	overflow: hidden;
	transition: all;
	transition-duration: 300ms;
	min-width: 340px;
	margin: 0 8px 20px 0;
	padding: 0;
	height: 1.7em;
	line-height: 1.7em;
	font-size: 21px;
	font-weight: var(--input-weight);

	&::placeholder {
		color: var(--input-placeholderColor);
		opacity: var(--input-placeholderOpacity);
	}

	&:-webkit-autofill,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:focus,
	&:-webkit-autofill::first-line {
		font-size: 21px;
		font-family: var(--font-sans-family);
	}

	&:focus {
		outline: none;
	}

	&[disabled] {
		cursor: not-allowed;
		border-color: var(--input-disabledColor);
		&::placeholder {
			color: var(--input-disabledPlaceholderColor);
		}
	}
`

const Input = (props) => {
	return (
		<input css={styles} {...props} />
	)
}

export default Input