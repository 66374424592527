import React from 'react'
import {css} from '@emotion/react'


const styles = css`
	position: relative;
	background: var(--container-background);
	color: var(--container-color);
	border: solid 1px var(--container-border-color);
	border-radius: var(--container-border-radius);
	overflow: hidden;
	transition: all;
	transition-duration: 300ms;
	min-width: 300px;
	padding: 30px 40px;

	*:first-of-type {
		margin-top: 0;
	}

	*:last-child {
		margin-bottom: 0;
	}
`

const Input = ({children, ...props}) => {
	return (
		<div css={styles} className="jds-simpleCard" {...props}>
			{children}
		</div>
	)
}

export default Input