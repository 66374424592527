import React from 'react'
import {css} from '@emotion/react'


const styles = css`
	background: transparent;
	text-align: center;

	&.error * {color: var(--color-error);}
	&.info * {color: var(--color-info);}
	&.warning * {color: var(--color-warning);}

	& > h5 {
		font-size: 18px;
		font-weight: 400;
	}
`

const Input = ({error, info, warning, className = '', ...props}) => {
	(error && (className += ' error'))
	|| (info && (className += ' info'))
	|| (warning && (className += ' warning'))

	let msg = error || warning || info

	return msg
		? (<div css={styles} {...{className}} {...props}><h5>{msg}</h5></div>)
		: null

}

export default Input