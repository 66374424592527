import React from 'react'
import {css} from '@emotion/react'
import If from '../../JDS/If/If'
import SignIn from '../../JDS/SignIn/SignIn'
import cs from '../../JDS/cookie/cookie'
import {signIn} from '../../api/user'
import {navTo} from '../../router'


export default function Login({route, authError}) {
	let {action, query} = route,
		{code, redir} = query,
		token = cs.get('token')

	if (token) {
		// redirection handled in operations authenticated
		console.log('Login.jsx no render', {token})
		return null

	} else {
		return (
			<div id="Login" className="page" css={css`text-align: center; margin-top: -20px !important`}>
				<SignIn error={authError} onSubmit={(email, complete) => {
					signIn(email, redir, (err, res) => {
						// console.log(err, res)
						complete()
					})
				}} />
			</div>
		)
	}
}



