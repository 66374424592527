import {Fragment} from 'react'
import {provide} from '../common/component/component'
import Login from './Login/Login'
import Claim from './Claim/Claim'
import Page from './Page/Page'
import Pages from './Pages/Pages'
import Base from './Base/Base'
import Notebooks from './Notebooks/Notebooks'
import Stream from './Stream/Stream'
import Account from './Account/Account'
import NotFound from './NotFound/NotFound'


let isLiveDev = !!window.location.href.match('localhost');

let views = {
	// property names reflect the action value for the intended route in appRoutes.js
	// 1st view is passed when using router function
	// 2nd view is used for nested routes, it is passed when using subrouter function

	// blank:					[Fragment],
	root:					[provide(Base, {route: 'route'})],

	login:					[provide(Login, {
								route: 'route',
								// keys: 'keys',
								authError: 'authError'
							})],

	claim:					[provide(Claim, {route: 'route'})],

	notebooks:				[provide(Notebooks, {route: 'route'})],
	stream:					[provide(Stream, {route: 'route'})],
	account:				[provide(Account, {route: 'route'})],
	pages:					[provide(Pages, {route: 'route'}),
							 provide(Page, {route: 'route', keys: 'keys'})],

	storybook:			 	isLiveDev
								  ? 'http://localhost:49612/?path=/story'
								  : `${location.origin}/assets/storybook/index.html`,

	missing:				[NotFound]
}

export default views