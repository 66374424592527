import {css} from '@emotion/react'
import {isPlainObject, forEach} from 'lodash'
import {setSatLum, setSat, cssVars} from '../../../styleUtils/styleUtils'
import {background, color, spacing} from '../../../shared/styles'
import {normalize} from '../../../shared/normalize'
import {typebase} from '../../../shared/typebase'
import {fonts} from '../../fonts'


export const type = {
	baseLineHeight:		1.55,
	headerWeight: 		500,
	bodyWeight:			300, //themeStyle === 'dark' ? 200 : 300,
	boldWeight:			480,
}

export const colors = {
	background: color.lightest,
	midground: color.mediumLight,
	foreground: color.darkest,

	primary: color.hotPurple,
	primaryFaded: setSatLum(color.hotPurple, 100, 90),
	primaryGrey: setSat(color.hotPurple, 0),

	error: color.red,
	info: color.seafoam,
	warning: color.gold,

	// green theme
	// primary: color.seafoam,
	// primaryFaded: setSatLum(color.seafoam, 70, 30),
	// primaryGrey: setSat(color.seafoam, 0)

}

export const metrics = { // css vars
	radius: '4px',
	radius2: '12px',
	baseUnit: 16, // 1 rem is this many pixels
}

export const elements = { // css vars
	body: {
		background: colors.background,
		border: colors.midground,
	},

	text: {
		color: colors.foreground,
	},

	headline: {
		color: colors.primary,
	},

	link: {
		color: colors.primary,
		underlineColor: colors.primary,
		underlineColorHover: colors.primary
	},

	container: {
		color: colors.foreground,
		background: 'transparent',
		border: {
			radius: metrics.radius2,
			color: colors.primaryFaded,
		}
	},

	button: {
		color: 			colors.primary,
		outlineActive:  colors.primary,
		background: 	colors.background,
		outline:		colors.primaryFaded,
		radius:			metrics.radius,
	},

	menu: {
		color: colors.foreground,
		background: colors.background,
		shadow: `0 2px 15px 5px ${colors.midground}`,
		weight: 300,
		letterSpacing: '0.022rem',

		indicator: {
			color: colors.primary,
			background: colors.primaryFaded
		},

		hover: {
			color: colors.foreground,
			weight: 600
		},

		border: {
			color: colors.primaryFaded,
			radius: metrics.radius,
		}
	},

	input: {
		// color: colors.primary,
		color: setSatLum(colors.primary, 100, 50),
		weight: 300,
		disabledColor: colors.primaryGrey,
		placeholderOpacity: 0.8,
		placeholderColor: colors.primary,
		disabledPlaceholderColor: colors.primaryGrey,
		border: {
			color: colors.primary,
		}
	}
}


export const styles = theme => css`
	a,
	a:link,
	a:visited {
		color: var(--link-color);
		text-decoration: underline;
		text-underline-offset: .09rem;
		text-decoration-color: var(--link-underlineColor);
		text-decoration-thickness: 1px;
	}

	a:hover {
		text-decoration-thickness: 2px;
		text-decoration-color: var(--link-underlineColorHover);
	}

	body {
		margin: var(--body-margin);
		background: var(--body-background);
		transition: background-color;
		transition-duration: 300ms;
	}

	* {
		outline-color: transparent;
	}

	html,
	body,
	p,
	div,
	span,
	table, tr, th, td,
	ul,
	ol,
	dl,
	li,
	div {
		color: var(--text-color);
		font-weight: var(--type-bodyWeight);
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: var(--headline-color);
	}

	*:focus {
		outline: none;
	}

	details summary {
		cursor: pointer;
	}

	details summary > * {
		display: inline;
	}
`


export const theme = {
	key: 'light', // the string passed into theme function
	title: 'App Light',
	style: 'light',
	fonts,
	tokens: {
		type,
		colors,
		metrics,
		...elements
	},
	styles: [
		normalize,
		typebase,
		styles
	]
}

export default theme