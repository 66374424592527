import {useState, useEffect} from 'react'
import {includes} from 'lodash'
import {QueryClient, QueryClientProvider, useQuery} from 'react-query'
import Title from 'react-document-title'
import {GlobalStyleFragment} from './JDS/shared/global'
import ThemeButton from './JDS/ThemeButton/ThemeButton'
import If from './JDS/If/If.jsx'
import cookie from './JDS/cookie/cookie'
import events from './common/events/events'
import store from './common/store/store'
import AppBar from './views/AppBar/AppBar'
import {queryFn} from './api/req'
import {router} from './router'
import './App.styl'


const queryClient = new QueryClient({
	defaultOptions: {
		queries: {queryFn}
	}
})

function topLevelClick(e) {
	events.emit('TOPLEVELCLICK', e)
}

function topLevelMouseDown(e) {
	events.emit('TOPLEVELMOUSEDOWN', e)
}

let currentTheme = document.querySelector('body').dataset.theme

function setDocTheme(theme) {
	document.querySelector('body').dataset.theme = theme
}


let skipAuth = (window.location + '').match(':3000')

if (skipAuth) {
	window.cookies.set('at', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFuZHJldy5sdWV0Z2Vyc0BnbWFpbC5jb20iLCJpZCI6IjAxRjZSSFQwNkNSM1hORFhLM1ZNUUU1OVY4IiwidHMiOiIxNjIyMTc0MTM4NTczIiwiaWF0IjoxNjIyMTc0MTM4fQ.hJ-KCv-UqNkKT6lSdfSA4SSsa8THFXtLrBDYnBKUs4E', {
		expires: new Date(new Date().getTime() + 60 * 60 * 1000)
	})

	window.cookies.set('st', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFuZHJldy5sdWV0Z2Vyc0BnbWFpbC5jb20iLCJpZCI6IjAxRjY4WkhNNURNNkZFRjBWWlpaWFlSU0paIiwidHMiOiIxNjIxNjUxNjczMjYxIiwiaWF0IjoxNjIxNjUxNjczfQ.JDcNvaI7cVX0mX6z6v-tVuCunMsgxOLg4BHK2rYboVs', {
		expires: new Date(new Date().getTime() + 60 * 60 * 1000)
	})
}


export default function App({route, modal}) {
	let [theme, setThemeState] = useState(currentTheme),
		token = cookie.get('st'),
		className = route.action + ' appMain',
		RouteHandler = router(route.action),
		appPage = route.action !== 'root',
		isPublic = includes(['root', 'login', 'claim'], route.action),
		block = !token && !isPublic, // prevents flashing content before redirect to login
		setTheme = theme => {
			setThemeState(theme)
			setDocTheme(theme)
			store.setItem('theme', theme)
		}

	useEffect(() => {
		(async function themeFn() {
			let theme = await store.getItem('theme')
			theme && setTheme(theme)
		})()
	}, [])

	return (
		<QueryClientProvider client={queryClient}>
			<GlobalStyleFragment {...{theme}}>
				<Title title='App'>
					<div id="appRoot" className={className} onClick={topLevelClick} onMouseDown={topLevelMouseDown}>
						<div id="appContents" className="">
							<div id="main">
								<ThemeButton {...{theme, setTheme}} />

								<If is={appPage && !block}>
									<If is={!isPublic}><AppBar {...{route}} /></If>
									<RouteHandler {...{route, token}}/>
								</If>

								{/*<If is={notFound}>*/}
								{/*	<div className="page notFoundContent">*/}
								{/*		<br />*/}
								{/*		<br />*/}
								{/*		<br />*/}
								{/*		<h1>Not Found</h1>*/}
								{/*	</div>*/}
								{/*</If>*/}

								<div id="modal"></div>
							</div>
						</div>
					</div>
				</Title>
			</GlobalStyleFragment>
		</QueryClientProvider>
	)
}