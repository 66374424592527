import {useIsFetching} from 'react-query'
import {ReactComponent as Transfer} from '../../JDS/SvgIcons/transfer.svg'
import {css} from '@emotion/react'


export default function GlobalLoadingIndicator() {
	let className = useIsFetching() ? 'isFetching' : ''

	return (
		<div className={className} css={styles}>
			<Transfer />
		</div>
	)
}

let styles = css`
	position: absolute;
	top: 14px;
	right: 50px;
	height: 18px;
	width: 18px;
	line-height: 18px;
	opacity: 0;
	transition: opacity 0.3s;

	&.isFetching {
		opacity: 0.8;
	}

	#transfer {
		fill: var(--button-outlineActive);
		stroke: var(--button-outlineActive);
		stroke-width: 2px;
	}
`