import Cookies from 'js-cookie'

// https://github.com/js-cookie/js-cookie
Cookies.defaults = {
	path: '/',
	domain: '.junipernotebook.com',
	// domain: 'localhost',
	sameSite: 'strict',
	secure: true
}

const cookies = Cookies

window.cookies = cookies
export default cookies
