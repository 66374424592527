
// this file loaded in client and server so must be node compatible

const config = {
	baseRoute: '',
	// publicApiPath: 'https://www.junipernotebook.com/api',
	publicApiPath: '/api',
	apiTimeout: 5000
}

export default config