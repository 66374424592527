import React from 'react'
import Title from 'react-document-title'
import {css} from '@emotion/react'
import {sendMessage} from '../../api/ws'
import {listNotes} from '../../api/notes'
import {subrouter} from '../../router'


let outlineButton = css`
	background-color: red;
	&:hover {
		background-color: blue;
	}
`


export default class Pages extends React.Component {
	constructor(props) {
		super(props)
		this.state = {value: ''}
		this.handleChange = this.handleChange.bind(this)
		this.handleSubmit = this.handleSubmit.bind(this)
	}

	handleChange(e) {
		this.setState({value: e.target.value})
	}

	handleSubmit(e) {
		e.preventDefault()
		sendMessage(this.state.value)
		this.setState({value: ''})
		console.log('send', this.state.value)
		this.notes()
	}

	notes() {
		listNotes(this.props.token, 'andrew.luetgers@gmail.com', function() {
			console.log('notes', arguments)
		})
	}

	render() {
		let SubRoute = subrouter('pages')

		return (
			<Title title='Pages'>
				<div id="pages" className="page">
					<h1>Pages</h1>

					<form onSubmit={this.handleSubmit}>
						<input type="text" value={this.state.value} onChange={this.handleChange} />
						<button type="submit" css={outlineButton}>Send</button>
					</form>

					<SubRoute token={this.props.token} />


				</div>
			</Title>
		)
	}
}