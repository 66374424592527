import React from 'react'
import {css} from '@emotion/react'
import {navTo} from '../../router'
// import Button from '../../JDS/Button/Button'
// import {ReactComponent as At} from '../../JDS/SvgIcons/at.svg'
import Nav from '../../JDS/Nav/Nav'
import GlobalLoadingIndicator from '../GlobalLoadingIndicator/GlobalLoadingIndicator'
// import Avatar from '../../JDS/Avatar/Avatar'


let styles = css`
	//border-bottom: solid 1px var(--body-border);
	position: absolute;
	top: 8px;
	left: 0;
	right: 0;
	height: 54px;
	line-height: 40px;
	z-index: 10;
	//border-bottom: solid 1px var(--container-border-color);

	.jds-nav {
		position: absolute;
		top: 2px;
		left: 20px;

		.jds-menu-list {
			min-width: 136px;
		}
	}
`

export default function AppBar({route}) {
	return (
		<div id="AppBar" css={styles}>
			<Nav selection={route.name} onSelection={item => navTo(item.label)}/>
			<GlobalLoadingIndicator />
		</div>
	)
}