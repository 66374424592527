import React, {useState} from 'react'
import {map} from 'lodash'
import ClickAwayListener from 'react-click-away-listener'
import {css} from '@emotion/react'
import Menu from '../Menu/Menu'
import Button from '../Button/Button'
import {ReactComponent as Notebook} from '../SvgIcons/notebook.svg'



const styles = css`
	position: relative;
	height: 40px;

	.jds-menu-button {
		height: 40px;
	}

	.iconButton3 {
		overflow: visible;
		margin: 0;

		& > svg, & > h4 {
			float: left;
		}

		& > h4 {
			display: inline-block;
			margin: 0;
			height: 40px;
			padding: 0 0 0 10px;
			font-weight: var(--menu-hover-weight);
			line-height: 42px;
		}
	}

	.jds-menu-list {
		padding: 10px 0;
	}

	li {
		line-height: 32px;
	//	border-left: solid 4px transparent;
    //
	//	&:hover {
	//		border-left-color: var(--menu-border-color);
	//	}
	}
`


export default function Nav({items, itemView, listParent, selection, onSelection, ...props}) {
	let [active, setActive] = useState(selection || "Notebooks"),
		menuItemView = (item, i) => (
			<li key={item.label} onClick={e => {
				setActive(item.label)
				onSelection?.(item, e)
			}}>{item.label}</li>
		)

	return (
		<div className="jds-nav" css={styles}>
			<Menu {...{
				items: items || [
					{label: 'Notebooks'},
					{label: 'Stream'},
					{label: 'Account'},
				],
				itemView: itemView || menuItemView
			}}>
				<Button className="iconButton3">
					<Notebook style={{width: 50, height: 40}}/>
					<h4>{active}</h4>
				</Button>
			</Menu>
		</div>
	)
}

