import React from 'react'
import {map, isFunction, isString} from 'lodash'
import {keyify} from '../stringUtils/stringUtils'


export default function List({items = [], itemView, className = '', id, ...props}) {
	id = id ?? Math.round(Math.random() * 1e10) + ''
	className += ' jds-list'

	return (
		<div {...{className, id, ...props}}>
			<ul>
				{map(items, (item, i) => {
					let idVal = item.id ?? i,
						key = keyify(idVal),
						itemId = `${id}_${key}`

					return (
						<li {...{key, id: itemId, ...item.props}}>
							{itemToNode(item, idVal, itemView, id)}
						</li>
					)
				})}
			</ul>
		</div>
	)
}

export function getItems({
	items = [],
	itemView,
	parentId = '',
	selected
}) {
	return map(items, (item, i) => {

		let idVal = item.id ?? i,
			keyVal = keyify(idVal),
			itemId = `${parentId}_${keyVal}`

		let isSelected = item === selected
		return itemToNode(item, idVal, itemView, parentId, {keyVal, itemId, isSelected})
	})
}

export function itemToNode(item, itemId, itemView, listId, more) {
	let isFn = isFunction(item),
		isNode = React.isValidElement(item),
		child = isFunction(item.view)
			? item.view?.(item)
			: (item.view || item.label || (isString(item) && item))

	if (isFn) {
		return item(itemId)
	} else if (isNode) {
		return item
	} else if (itemView) {
		return itemView(item, itemId, listId, more)
	} else if (child) {
		return child
	} else {
		return item
	}
}