import If from '../../JDS/If/If'
import cs from '../../JDS/cookie/cookie'
import {Link} from '../../router'


export default function({route}) {
	return (
		null
	)
}

