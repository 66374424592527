import Sockette from 'sockette'
import cookie from '../JDS/cookie/cookie'


let ws

export function connect() {
	let token = cookie.get('token')
	if (token) {
		// set connection status = connecting
		console.log('token', token)
		ws = new Sockette(`wss://ws.junipernotebook.com/?auth=${token}`,
			{
				timeout: 5e3,
				maxAttempts: 3,
				onopen: e => {
					connection.status = 1
					console.log('connected:', e)
				}, // set connection status = connected
				onmessage: e => console.log('Message Received:', e),
				onreconnect: e => console.log('Reconnecting...', e),
				onmaximum: e => {
					console.log('Stop Attempting!', e)
				},
				onclose: e => {
					connection.status = 0
					console.log('Closed!', e) // set connection status = closed
					ws.reconnect()
				},
				onerror: e => console.log('Error:', e), // set connection status = error??
			})
	} else {
		console.log('no token no ws connection') // set connection status = not connected
	}


}

// Send Message
export function sendMessage(msg) {
	console.log('sendMessage', msg)
	ws.json({
		action: '$default',
		data: msg,
	})
}

export let connection = {
	status: 0
}